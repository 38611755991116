.container {
  width: 100%
}

@media (min-width: 640px) {
  .container {
    max-width: 640px
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px
  }
}

.visible {
  visibility: visible
}

.invisible {
  visibility: hidden
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.sticky {
  position: -webkit-sticky;
  position: sticky
}

.left-0 {
  left: 0px
}

.top-0 {
  top: 0px
}

.z-10 {
  z-index: 10
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.mb-24 {
  margin-bottom: 6rem
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.flex {
  display: flex
}

.inline-flex {
  display: inline-flex
}

.grid {
  display: grid
}

.hidden {
  display: none
}

.h-\[870px\] {
  height: 870px
}

.w-11\/12 {
  width: 91.666667%
}

.w-6 {
  width: 1.5rem
}

.w-9\/12 {
  width: 75%
}

.w-full {
  width: 100%
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr))
}

.flex-col {
  flex-direction: column
}

.flex-col-reverse {
  flex-direction: column-reverse
}

.items-center {
  align-items: center
}

.justify-center {
  justify-content: center
}

.gap-10 {
  gap: 2.5rem
}

.gap-4 {
  gap: 1rem
}

.self-center {
  align-self: center
}

.\!rounded-3xl {
  border-radius: 1.5rem !important
}

.rounded-2xl {
  border-radius: 1rem
}

.rounded-xl {
  border-radius: 0.75rem
}

.border {
  border-width: 1px
}

.bg-blue-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(28 107 211 / var(--tw-bg-opacity))
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.fill-amber-600 {
  fill: #d97706
}

.fill-white {
  fill: #fff
}

.object-cover {
  object-fit: cover
}

.object-right-bottom {
  object-position: right bottom
}

.p-2 {
  padding: 0.5rem
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem
}

.text-center {
  text-align: center
}

.\!text-3xl {
  font-size: 1.875rem !important;
  line-height: 2.25rem !important
}

.\!text-4xl {
  font-size: 2.25rem !important;
  line-height: 2.5rem !important
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem
}

.text-5xl {
  font-size: 3rem;
  line-height: 1
}

.text-\[2\.50rem\] {
  font-size: 2.50rem
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem
}

.\!font-bold {
  font-weight: 700 !important
}

.\!font-extrabold {
  font-weight: 800 !important
}

.\!font-semibold {
  font-weight: 600 !important
}

.font-bold {
  font-weight: 700
}

.font-extrabold {
  font-weight: 800
}

.font-light {
  font-weight: 300
}

.uppercase {
  text-transform: uppercase
}

.leading-loose {
  line-height: 2
}

.leading-tight {
  line-height: 1.25
}

.tracking-wide {
  letter-spacing: 0.025em
}

.\!text-blue-950 {
  --tw-text-opacity: 1 !important;
  color: rgb(28 107 211 / var(--tw-text-opacity)) !important
}

.\!text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity))
}

.text-blue-950 {
  --tw-text-opacity: 1;
  color: rgb(28 107 211 / var(--tw-text-opacity))
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.underline {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline
}

.no-underline {
  -webkit-text-decoration-line: none;
          text-decoration-line: none
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.transition {
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

@media (min-width: 640px) {
  .sm\:h-3\/4 {
    height: 75%
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .sm\:flex-row {
    flex-direction: row
  }

  .sm\:gap-10 {
    gap: 2.5rem
  }

  .sm\:object-bottom {
    object-position: bottom
  }

  .sm\:p-8 {
    padding: 2rem
  }

  .sm\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem
  }

  .sm\:\!pt-20 {
    padding-top: 5rem !important
  }

  .sm\:text-left {
    text-align: left
  }
}
