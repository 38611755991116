/* fonts.css */
@font-face {
    font-family: 'Taviraj'; /* Choose a unique name for your font */
    src: url('./fonts/Taviraj/Taviraj-MediumItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

*:not(.fa):not(.far):not(.fas):not(.fab):not(.material-icons) {
    font-family: 'Taviraj' !important;
}
